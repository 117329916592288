export const SUBACCOUNT_TYPES = {
  BENEFICIARY: 'BENEFICIARY',
  FOSTER_FAMILY: 'FOSTER_FAMILY'
}

export const SUBACCOUNT_TYPE_LABELS = {
  [SUBACCOUNT_TYPES.BENEFICIARY]: 'Podopieczny',
  [SUBACCOUNT_TYPES.FOSTER_FAMILY]: 'Rodzina zastępcza'
}

export const FOSTER_FAMILY_TYPES = {
  RELATED: 'RELATED',
  NON_PROFESSIONAL: 'NON_PROFESSIONAL',
  PROFESSIONAL: 'PROFESSIONAL',
  FAMILY_CHILDREN_HOME: 'FAMILY_CHILDREN_HOME',
  OTHER: 'OTHER'
}

export const FOSTER_FAMILY_TYPE_LABELS = {
  [FOSTER_FAMILY_TYPES.RELATED]: 'Rodzina spokrewniona',
  [FOSTER_FAMILY_TYPES.NON_PROFESSIONAL]: 'Rodzina niezawodowa',
  [FOSTER_FAMILY_TYPES.PROFESSIONAL]: 'Rodzina zawodowa',
  [FOSTER_FAMILY_TYPES.FAMILY_CHILDREN_HOME]: 'Rodzinny dom dziecka',
  [FOSTER_FAMILY_TYPES.OTHER]: 'Inne'
} 
