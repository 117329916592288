<script>
import {maxLength, minLength, required} from 'vuelidate/lib/validators'
import Vue from "vue";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import Repository from "../../app/repository/repository-factory";

const FosterChildrenRepository = Repository.get("FosterChildrenRepository");

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 50;
const MAX_DISEASE_LENGTH = 255;

// Własne walidatory
const notEmptyString = (value) => {
    return value && value.trim().length > 0;
};

const dateNotInFuture = (value) => {
    if (!value) return true;
    const today = new Date();
    return new Date(value) <= today;
};

const notTooOld = (value) => {
    if (!value) return true;
    const today = new Date();
    const maxAge = new Date(today.getFullYear() - 26, today.getMonth(), today.getDate());
    return new Date(value) >= maxAge;
};

export default {
    components: { DatePicker,  },
    props: {
        subaccountUuid: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        childrenCount() {
            return this.fosterChildren.length;
        }
    },
    watch: {
        childrenCount(newValue) {
            this.$emit('count-changed', newValue);
        }
    },
    data() {
        return {
            preloader: true,
            submitted: false,
            inProgress: false,
            fosterChildren: [],
            form: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                disease: ''
            },
            editedItem: null,
            MIN_NAME_LENGTH,
            MAX_NAME_LENGTH,
            MAX_DISEASE_LENGTH,
            fields: [
                {
                    key: 'fullName',
                    label: 'Imię i nazwisko',
                    tdClass: 'text-wrap',
                    thClass: 'w-40'
                },
                {
                    key: 'dateOfBirth',
                    label: 'Data urodzenia',
                    tdClass: 'text-nowrap',
                    thClass: 'w-20'
                },
                {
                    key: 'disease',
                    label: 'Choroba',
                    tdClass: 'text-wrap',
                    thClass: 'w-30'
                },
                {
                    key: 'actions',
                    label: 'Akcje',
                    tdClass: 'text-center',
                    thClass: 'w-10 text-center'
                }
            ],
            fieldsReadOnly: [
                {
                    key: 'fullName',
                    label: 'Imię i nazwisko',
                    tdClass: 'text-wrap',
                    thClass: 'w-40'
                },
                {
                    key: 'dateOfBirth',
                    label: 'Data urodzenia',
                    tdClass: 'text-nowrap',
                    thClass: 'w-30'
                },
                {
                    key: 'disease',
                    label: 'Choroba',
                    tdClass: 'text-wrap',
                    thClass: 'w-30'
                }
            ]
        }
    },
    validations: {
        form: {
            firstName: {
                notEmptyString,
                minLength: minLength(MIN_NAME_LENGTH),
                maxLength: maxLength(MAX_NAME_LENGTH)
            },
            lastName: {
                notEmptyString,
                minLength: minLength(MIN_NAME_LENGTH),
                maxLength: maxLength(MAX_NAME_LENGTH)
            },
            dateOfBirth: {
                required,
                dateNotInFuture,
                notTooOld
            },
            disease: {
                required,
                maxLength: maxLength(MAX_DISEASE_LENGTH)
            }
        }
    },
    created() {
        this.loadFosterChildren();
    },
    methods: {
        loadFosterChildren() {
            this.preloader = true;
            FosterChildrenRepository.getFosterChildren(this.subaccountUuid)
                .then((response) => {
                    this.fosterChildren = response.data;
                    this.preloader = false;
                })
                .catch(error => {
                    console.log(error);
                    this.preloader = false;
                });
        },
        addFosterChild() {
            this.formSubmit();

            if (this.$v.form.$error === true) {
                return false;
            }

            this.inProgress = true;

            FosterChildrenRepository.addFosterChild(this.subaccountUuid, this.form)
                .then(() => {
                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES!",
                        text: 'Podopieczny został dodany!',
                        showConfirmButton: false,
                        timer: 1500,
                        onClose: () => {
                            this.resetForm();
                            this.loadFosterChildren();
                        }
                    });
                    this.inProgress = false;
                })
                .catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true
                    });
                    this.inProgress = false;
                });
        },
        editFosterChild(item) {
            this.editedItem = item;
            this.form = {
                firstName: item.firstName,
                lastName: item.lastName,
                dateOfBirth: item.dateOfBirth,
                disease: item.disease
            };
        },
        updateFosterChild() {
            this.formSubmit();

            if (this.$v.form.$error === true) {
                return false;
            }

            this.inProgress = true;

            FosterChildrenRepository.updateFosterChild(this.subaccountUuid, this.editedItem.uuid, this.form)
                .then(() => {
                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES!",
                        text: 'Dane podopiecznego zostały zaktualizowane!',
                        showConfirmButton: false,
                        timer: 1500,
                        onClose: () => {
                            this.resetForm();
                            this.loadFosterChildren();
                        }
                    });
                    this.inProgress = false;
                })
                .catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true
                    });
                    this.inProgress = false;
                });
        },
        confirmDelete(item) {
            Vue.swal({
                title: "Czy na pewno chcesz usunąć tego podopiecznego?",
                text: `${item.firstName} ${item.lastName}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Tak, usuń!",
                cancelButtonText: "Anuluj"
            }).then(result => {
                if (result.value) {
                    this.deleteFosterChild(item);
                }
            });
        },
        deleteFosterChild(item) {
            this.inProgress = true;
            FosterChildrenRepository.deleteFosterChild(this.subaccountUuid, item.uuid)
                .then(() => {
                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES!",
                        text: 'Podopieczny został usunięty!',
                        showConfirmButton: false,
                        timer: 1500,
                        onClose: () => {
                            this.loadFosterChildren();
                        }
                    });
                    this.inProgress = false;
                })
                .catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true
                    });
                    this.inProgress = false;
                });
        },
        formSubmit() {
            this.submitted = true;
            this.$v.form.$touch();
        },
        resetForm() {
            this.submitted = false;
            this.editedItem = null;
            this.form = {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                disease: ''
            };
            this.$v.form.$reset();
        }
    }
};
</script>

<template>
    <div class="foster-children-manager">
        <b-overlay :show="preloader" rounded="sm">
            <div class="card">
                <div class="card-body">
                    <form v-if="!readOnly" class="needs-validation" @submit.prevent="editedItem ? updateFosterChild() : addFosterChild()">
                        <b-row>
                            <b-col md="2">
                                <div class="form-group">
                                    <input
                                        v-model="form.firstName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Imię"
                                        :class="{ 'is-invalid': submitted && $v.form.firstName.$error }"
                                    />
                                    <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.firstName.notEmptyString">Imię nie może być puste.</span>
                                        <span v-if="!$v.form.firstName.minLength">Imię musi mieć co najmniej {{ MIN_NAME_LENGTH }} znaki.</span>
                                        <span v-if="!$v.form.firstName.maxLength">Imię nie może być dłuższe niż {{ MAX_NAME_LENGTH }} znaków.</span>
                                    </div>
                                </div>
                            </b-col>

                            <b-col md="3">
                                <div class="form-group">
                                    <input
                                        v-model="form.lastName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Nazwisko"
                                        :class="{ 'is-invalid': submitted && $v.form.lastName.$error }"
                                    />
                                    <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.lastName.notEmptyString">Nazwisko nie może być puste.</span>
                                        <span v-if="!$v.form.lastName.minLength">Nazwisko musi mieć co najmniej {{ MIN_NAME_LENGTH }} znaki.</span>
                                        <span v-if="!$v.form.lastName.maxLength">Nazwisko nie może być dłuższe niż {{ MAX_NAME_LENGTH }} znaków.</span>
                                    </div>
                                </div>
                            </b-col>

                            <b-col md="2">
                                <div class="form-group">
                                    <date-picker
                                        v-model="form.dateOfBirth"
                                        :first-day-of-week="1"
                                        value-type="format"
                                        placeholder="Data urodzenia"
                                        :class="{ 'is-invalid': submitted && $v.form.dateOfBirth.$error }"
                                        lang="pl">
                                    </date-picker>
                                    <div v-if="submitted && $v.form.dateOfBirth.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.dateOfBirth.required">Data urodzenia jest wymagana.</span>
                                        <span v-if="!$v.form.dateOfBirth.dateNotInFuture">Data urodzenia nie może być z przyszłości.</span>
                                        <span v-if="!$v.form.dateOfBirth.notTooOld">Wiek dziecka przekracza maksymalny dozwolony wiek dla pieczy zastępczej (26 lat).</span>
                                    </div>
                                </div>
                            </b-col>

                            <b-col md="3">
                                <div class="form-group">
                                    <input
                                        v-model="form.disease"
                                        type="text"
                                        class="form-control"
                                        placeholder="Choroba"
                                        :class="{ 'is-invalid': submitted && $v.form.disease.$error }"
                                    />
                                    <div v-if="submitted && $v.form.disease.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.disease.required">Opis schorzenia jest wymagany.</span>
                                        <span v-if="!$v.form.disease.maxLength">Opis schorzenia nie może być dłuższy niż {{ MAX_DISEASE_LENGTH }} znaków.</span>
                                    </div>
                                </div>
                            </b-col>

                            <b-col md="2">
                                <div class="form-group d-flex">
                                    <b-button
                                        :variant="editedItem ? 'warning' : 'primary'"
                                        type="submit"
                                        :disabled="inProgress"
                                    >
                                        {{ editedItem ? 'Aktualizuj' : 'Dodaj' }}
                                        <template v-if="inProgress">
                                            <b-spinner v-for="n in 3" :key="n" small class="ml-1" variant="light" role="status" type="grow"></b-spinner>
                                        </template>
                                    </b-button>
                                    <b-button
                                        v-if="editedItem"
                                        variant="light"
                                        class="ml-2"
                                        @click="resetForm"
                                        :disabled="inProgress"
                                    >
                                        Anuluj
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </form>

                    <div class="mt-4">
                        <b-table
                            :items="fosterChildren"
                            :fields="readOnly ? fieldsReadOnly : fields"
                            responsive
                            show-empty
                            empty-text="Brak dzieci pod opieką"
                            class="table-centered"
                        >
                            <template #cell(fullName)="data">
                                {{ data.item.firstName }} {{ data.item.lastName }}
                            </template>

                            <template #cell(actions)="data">
                                <div class="btn-group">
                                    <b-button
                                        variant="warning"
                                        size="sm"
                                        class="mr-2"
                                        @click="editFosterChild(data.item)"
                                        :disabled="inProgress"
                                    >
                                        <i class="bx bx-edit"></i>
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="sm"
                                        @click="confirmDelete(data.item)"
                                        :disabled="inProgress"
                                    >
                                        <i class="bx bx-trash"></i>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<style lang="scss" scoped>
.foster-children-manager {
    .form-group {
        margin-bottom: 1rem;
    }

    ::v-deep .table {
        th, td {
            vertical-align: middle;
        }

        .btn-group {
            display: inline-flex;
            align-items: center;
        }
    }

    .mx-datepicker {
        width: 100%;
    }
}
</style>
