import Client from '../axios-client';

const resource = '/subaccount/guardianapp/subaccount';

export default {
    // Pobieranie listy podopiecznych dla danego subkonta
    getFosterChildren(subaccountUuid) {
        return Client.get(`${resource}/${subaccountUuid}/foster-children`);
    }
};
